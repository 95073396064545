import React from 'react';
import {
  createStyles,
  useMantineColorScheme,
  useMantineTheme,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Image,
  Group,
  ActionIcon,
} from '@mantine/core';
import {
  BrandTwitter,
  BrandYoutube,
  BrandInstagram,
  BrandLinkedin,
  BrandGithub,
} from 'tabler-icons-react';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactIconsList } from '../ContactIcons/ContactIcons';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: 'border-box',
    backgroundImage: `linear-gradient(-60deg, ${
      theme.colorScheme === 'light' ? theme.colors.indigo[3] : theme.colors.violet[9]
    } 0%, ${theme.colorScheme === 'light' ? theme.colors.blue[3] : theme.colors.blue[6]} 100%)`,
    borderRadius: theme.radius.md,
    padding: theme.spacing.xl * 2.5,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      padding: theme.spacing.xl * 1.5,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: 400,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    '&:hover': {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    '&::placeholder': {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const social = [
  {
    icon: BrandLinkedin,
    href: 'https://linkedin.com/in/sauli-anto',
  },
  {
    icon: BrandGithub,
    href: 'https://github.com/isaul32',
  },
  {
    icon: BrandTwitter,
    href: 'https://twitter.com/SauliAnto',
  },
];

export function ContactMe() {
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const icons = social.map((data, index) => (
    <ActionIcon
      key={index}
      size={28}
      className={classes.social}
      component="a"
      href={data.href}
      target="_blank"
      variant="transparent"
    >
      <data.icon size={22} />
    </ActionIcon>
  ));

  return (
    <div className={classes.wrapper}>
      <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        <div>
          <StaticImage
            src="../../images/sauli.png"
            alt="Sauli Anto"
            backgroundColor={colorScheme === 'light' ? theme.white : theme.colors.dark[7]}
            style={{ borderRadius: theme.radius.md }}
            layout="fullWidth"
          />
        </div>
        <div>
          <Title className={classes.title}>Contact me</Title>
          <Text className={classes.description} mt="sm" mb={30}>
            Feel free to send me a message and say hello.
          </Text>

          <ContactIconsList variant="white" />

          <Group mt="xl">{icons}</Group>
        </div>
        {/* <div className={classes.form}>
          <TextInput
            label="Email"
            placeholder="your@email.com"
            required
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            required
            label="Name"
            placeholder="John Doe"
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <Textarea
            required
            label="Your message"
            placeholder="I want to say hello"
            minRows={4}
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />

          <Group position="right" mt="md">
            <Button className={classes.control}>Send message</Button>
          </Group>
        </div> */}
      </SimpleGrid>
    </div>
  );
}
