import React from 'react';
import dayjs from 'dayjs';

export function Copyright() {
  const sinceYear = dayjs().year(2022).year();
  const currentYear = dayjs().year();

  return (
    <>
      Copyright © {sinceYear === currentYear ? currentYear : `${sinceYear}-${currentYear}`} Sauli
      Anto. All rights reserved.
    </>
  );
}
