import React from 'react';
import { useMantineTheme, Center, Container, Title, Text } from '@mantine/core';
import { Layout } from '../components/Layout/Layout';
import { ContactMe } from '../components/ContactMe/ContactMe';
import { Copyright } from '../components/Copyright/Copyright';
import { HeaderSimple } from '../components/HeaderSimple/HeaderSimple';
import { Stats } from '../components/Stats/Stats';
import { Features } from '../components/Features/Features';
import { Helmet } from 'react-helmet';

export default function HomePage() {
  const theme = useMantineTheme();

  return (
    <Layout>
      <Helmet
        title="Sauli Anto"
        defer={false}
        htmlAttributes={{
          lang: 'en',
        }}
      />
      {/* <HeaderSimple
        links={[
          {
            to: '/',
            label: 'Home',
          },
          {
            to: '/posts',
            label: 'Posts',
          },
          {
            to: '/about',
            label: 'About',
          },
        ]}
      /> */}
      <main>
        {/* <section>
          <Features />
        </section>
        <section>
          <Container>
            <Title>Latest posts</Title>
            <article>
              <Title order={2}>Lorem ipsum</Title>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>
            </article>
          </Container>
        </section>
        <section>
          <Container>
            <Title>My GitHub metrics</Title>
            <Stats />
          </Container>
        </section>
        <section>
          <Container>
            <Title>Something</Title>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua...
            </p>
          </Container>
        </section> */}
        <section>
          <Container mt={theme.spacing.lg}>
            <ContactMe />
          </Container>
        </section>
      </main>
      <footer>
        <Container>
          <Center my={theme.spacing.lg}>
            <Text size="xs" color="dimmed">
              <Copyright />
            </Text>
          </Center>
        </Container>
      </footer>
    </Layout>
  );
}
